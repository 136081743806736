import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {currentAdminSelectedPeriodicalPlanSelector} from "../../../../redux/selectors/admin/periodical/admin-publication.selectors";
import TreeViewer from "../../../commons/tree/TreeViewer";
import {PlanNode} from "../../../../types/plan-node";
import Button from "@material-ui/core/Button";
import {
    saveAdminPeriodicalPlan,
    setAdminPeriodicalPlan,
    setDirty
} from "../../../../redux/actions/admin/periodical/admin-periodical.actions";
import useAsyncState from "../../../commons/hooks/useAsyncState";
import {useHistory} from "react-router-dom";

const textStyles = makeStyles((theme) => ({
    titleRoot: {
        padding: theme.spacing(2),
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.primary.dark
    },
    tree: {
        backgroundColor: "rgba(0,0,0,0.1)",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
        minHeight: "50vh"
    },
    topTitle: {
        fontWeight: 200
    },
    saveRoot: {
        paddingRight: theme.spacing(3),
    }
}));

const removeNulls = (plan: PlanNode[]) => plan.map(node => node.sub === null ? {...node, sub: []} : {
    ...node,
    sub: removeNulls(node.sub)
})

const PeriodicalOrganizeStructure = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = textStyles();
    const [loaded, setLoaded] = useState(false);
    const [nodes, setNodes] = useAsyncState([] as PlanNode[]);

    const planNodeCollection = useSelector(currentAdminSelectedPeriodicalPlanSelector);

    useEffect(() => {
        if (planNodeCollection?.plan) {
            setLoaded(false);
            setNodes(removeNulls(planNodeCollection.plan)).then(() => setLoaded(true));
        }
    }, [planNodeCollection])

    useEffect(() => {
        if (loaded) {
            dispatch(setAdminPeriodicalPlan(nodes));
        }
    }, [nodes])

    const savePeriodicalPlan = () => {
        if (planNodeCollection) {
            dispatch(saveAdminPeriodicalPlan(planNodeCollection));
        }
    }

    const cancel = () => {
        new Promise<void>((resolve) => {
            dispatch(setDirty(true));
            resolve();
        }).then(() => {
            history.push(`/`);
        });
    }

    return (
        <>
            {loaded && <Grid container spacing={2}>
                <Grid item xs={12} className={classes.titleRoot}>
                    <Typography className={classes.topTitle} variant={"h5"}
                                component={"h1"}>{t('admin.periodical.structure.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item className={classes.saveRoot} justify={"flex-end"} direction={"row"}
                          spacing={2}>
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={cancel}>
                                {t('misc.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} color={"primary"} onClick={savePeriodicalPlan}>
                                {t('misc.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TreeViewer
                        setDirty={setDirty}
                        detachLabel={t('admin.periodical.planNode.unlink')}
                        maxDepth={5}
                        preventSiblings={true}
                        edit={true}
                        canAddContainerNodes={true}
                        nodes={nodes}
                        setNodes={setNodes}
                        treeStyle={{maxHeight: '60vh', overflowY: 'auto'}}
                        customClasses={classes}
                        isBundleStructureEdition={false}
                    />
                </Grid>
            </Grid>}
        </>
    );
};

export default PeriodicalOrganizeStructure;
