import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PageHeader from "../commons/PageHeader";
import {
    initDefaultPeriodicalQueryParam,
    search,
    updateSearchQueryParam
} from "../../redux/actions/common-search.actions";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../utils/roles";
import TablePagination from "../authors/list/TablePagination";
import QuickResults from "../commons/QuickResults";
import ArticlesFilters from "./list/ArticlesFilters";
import {readArticleQueryParams} from "../../utils/article-utils";
import ArticlesTable from "./list/ArticlesTable";
import {commonStyle} from "../../styles/common.style";
import {State} from "../../redux/reducers";
import {SearchContext} from "../../types/common-search";
import {Button} from "@material-ui/core";

import {setArticleDialogMode} from "../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../redux/reducers/article/article-dialog.reducer";
import Auth from "../Auth";

type Props = {
    children?: JSX.Element
}

export default function ArticlesRoot({children}: Props) {
    const {t} = useTranslation()
    const classes = commonStyle()
    const dispatch = useDispatch()
    const [isInit, setInit] = useState(false);

    const searchLocation = useLocation().search;
    const filters = useMemo(() => readArticleQueryParams(searchLocation), [searchLocation])

    useEffect(() => {
        new Promise<void>((resolve) => {
            dispatch(initDefaultPeriodicalQueryParam(SearchContext.ARTICLE))
            resolve();
        }).then(() => setInit(true));
    }, [dispatch]);

    useEffect(() => {
        if (!isInit) {
            return;
        }

        const queryFilters = readArticleQueryParams(searchLocation);
        dispatch(search({
            ctx: SearchContext.ARTICLE,
            filters: queryFilters
        }))
    }, [dispatch, searchLocation, isInit]);

    const updateChangePage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ARTICLE,
            field: 'page',
            value
        }));
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ARTICLE,
            field: 'size',
            value
        }));
    }

    const totalCount = useSelector(({search}: State) => search.common[SearchContext.ARTICLE].totalCount)

    return (
        <>{filters && <>
            <Container maxWidth={"xl"} className={classes.root}>
                <PageHeader
                    label={filters.query ? "" : t('articles.list')}
                    requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, EDITOR, MANAGER]}
                    rightPanelChildren={
                        <Grid container spacing={1}>
                            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                                <Grid item>
                                    <Button variant={"outlined"} color={"primary"}
                                            onClick={() => dispatch(setArticleDialogMode({
                                                mode: ArticleDialogMode.CREATE,
                                                default: {
                                                    isBundle: true
                                                }
                                            }))}>
                                        {t('bundles.add')}
                                    </Button>
                                </Grid>
                            </Auth>
                            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}>
                                <Grid item>
                                    <Button variant={"outlined"} color={"primary"}
                                            onClick={() => dispatch(setArticleDialogMode({
                                                mode: ArticleDialogMode.CREATE
                                            }))}>
                                        {t('articles.add')}
                                    </Button>
                                </Grid>
                            </Auth>
                        </Grid>
                    }>
                    {filters.query ?
                        <QuickResults currentPage={"article"} countResult={totalCount}
                                      query={filters.query!!}/> : <></>}
                </PageHeader>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ArticlesFilters filters={filters}/>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid item xs={12}>
                            <ArticlesTable/>
                            <TablePagination page={filters.page || 0}
                                             size={filters.size || 0}
                                             totalCount={totalCount}
                                             rowsPerPage={[10, 20, 30, 50, 100]}
                                             updateChangePage={updateChangePage}
                                             updateChangeRowPerPage={updateChangeRowPerPage}/>
                        </Grid>
                    </Grid>
                    {children}
                </Grid>
            </Container>
        </>}</>
    )
}
