import {PhoneTypes} from "./author";
import {ImageColor, ImageFormat, ImageFraming, MetadataImage} from "./image";
import {ArticleAttachment, ArticleNature, ArticleStatus, ArticleType} from "./article";
import {EditionType, PublicationStatus} from "./publication";
import {BundleType} from "./bundle";
import {JouveProcessStatus} from "./workflow";
import {DateTime} from "luxon"

// AutoComplete //

export type AutoComplete = {
    attribute: 'city' | 'country',
    query: string
}

export type autoCompleteStatus = 'NONE' | 'PENDING' | 'LOADED' | 'ERROR';

export type AutoCompleteResults = {
    country: AutoCompleteResult,
    city: AutoCompleteResult
}

export type AutoCompleteResult = {
    options: [],
    status: autoCompleteStatus
}

export type DateRange = {
    start?: string, end?: string
}


// export type SearchContext = 'AUTHOR' | 'IMAGE' | 'ADVERTISEMENT' | 'PUBLICATION' | 'ARTICLE';
export enum SearchContext {
    AUTHOR = "AUTHOR",
    IMAGE = "IMAGE",
    ADVERTISEMENT = "ADVERTISEMENT",
    PUBLICATION = "PUBLICATION",
    ARTICLE = "ARTICLE",
    PUBLICATION_ARTICLE = "PUBLICATION_ARTICLE",
    PUBLICATION_AD = "PUBLICATION_AD",
    BUNDLE_ARTICLE = "BUNDLE_ARTICLE",
    BUNDLE_AD = "BUNDLE_AD"
}

// export type SearchContext = 'AUTHOR' | 'IMAGE' | 'ADVERTISEMENT' | 'PUBLICATION' | 'ARTICLE';
export enum ElementContext {
    ELEMENT_CONTEXT_ARTICLE = "ELEMENT_CONTEXT_ARTICLE"
}

// Search Filters //

export type Filters = AuthorSearchFilters | ImageSearchFilters | PublicationSearchFilters | ArticleSearchFilters

export type PageSize = 20 | 30 | 50 | 100

export type Sort = {
    attr: string
    order: 'ASC' | 'DESC'
}

export type CommonSearchFilter = {
    page?: number
    size?: PageSize
    query?: string
    sort?: Sort[]
    grid?: boolean,
    mode?: string
}

export type PlanNodeFilters = {
    page?: number,
    size: PageSize,
    query: string
}

export type AuthorSearchFilters = CommonSearchFilter & {
    enableFilter?: boolean[],
    typeFilter?: string[],
    publicationPeriodicalFilter?: string[],
    cityFilter?: string[],
    countryFilter?: string[],
    publicationDateFilter?: DateRange,
    withImageFilter?: boolean[]
}

export type ImageSearchFilters = CommonSearchFilter & {
    modificationDateFilter?: DateRange,
    publicationPeriodicalFilter?: string[],
    periodicalFilter?: string[],
    formatFilter?: string[],
    characteristicFilter?: string[], //LARGE, THUMBNAIL, COLOR, BLACK_AND_WHITE, PORTRAIT, SKETCH, WIDE_PLAN, BAD_QUALITY
    collectionFilter?: string[],//'ILLUSTRATION' | 'AUTHOR' | 'ADVERTISEMENT'
    endDateFilter?: DateRange,
    enableFilter?: boolean[],
    mimeTypeFilter?: string[], //PNG, JPG
    priorityFilter?: boolean[]
}

export type PublicationSearchFilters = CommonSearchFilter & {
    periodicalIdFilter?: string[],
    startDateFilter?: DateRange,
    editionTypeFilter?: string[],
    statusFilter?: string[],
    imageFilter?: string[]
}

export type ArticleSearchFilters = CommonSearchFilter & {
    statusFilter?: string[],
    typeFilter?: string[],
    natureFilter?: string [],
    periodicalFilter?: string[],
    publicationIdFilter?: string[],
    marbre?: boolean,
    publicationFilter?: string[],
    authorFilter?: string[],
    imageFilter?: string[],
    planNodeFilter?: string[],
    publicationDateFilter?: DateRange,
    attachedFilter?: ArticleAttachment[],
    attachedFilterBis?: ArticleAttachment[],
    onlyBundle: boolean[],
    bundleFilter?: string[],
    numberOfPagesFilter?: string[]
}

// Search Results //

export type SearchResults = {
    result: any[],
    page: number,
    resultCount: number
}

export type AuthorSearchResult = {
    id: string,
    imageAuthorId?: string,
    type: string,
    particle: string,
    firstName?: string,
    lastName: string,
    functions?: string[],
    phoneNumberFav?: { countryCode: string, number: string, type: PhoneTypes }
    emailFav?: string,
    modificationDate: string,
    publicationPeriodicals: string[],
    score: number,
    version: number
}

export type CommonImageResult = {
    id: string
    name: string
    meta: MetadataImage
    collection: string
    used: boolean
    enable: boolean
    format: ImageFormat
    formatLabel: string
    color: ImageColor
    framing: ImageFraming
    uploadDate: string
    modificationDate: string
    publicationPeriodicals: string[]
    characteristics?: string[]
    authorId?: string
    sources?: string
    credits?: string,
    version: number
}

export type ImageSearchResult = CommonImageResult & {}

export type AdSearchResult = CommonImageResult & {
    comments: string
    priority: boolean
    deadline: string
    periodicals: string[]
}

export type PublicationSearchResult = {
    id: number
    periodicalId: string
    year: number
    number: string
    editionType: EditionType
    status: PublicationStatus
    startDate: string
    endDate: string
    modificationDate: string
    lastRequestPlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcess?: string
    lastPrinterPdfProcessStatus?: JouveProcessStatus
    validationDate?: string
    allArticlesReady: boolean,
    publicationOnlineDate?: string,
	scheduledDate: DateTime,
	username: string
}

export type ArticleSearchResult = {
    id: string
    title?: string
    bundleId?: string
    planNode?: number
    planNodeId: string
    periodicalId: string
    publication?: string
    publicationId?: number
    modificationDate?: string
    creationDate: string
    type: ArticleType | BundleType
    nature?: ArticleNature
    status: ArticleStatus
    attached: ArticleAttachment,
    isBundle: boolean,
    isFrontCover?: boolean,
    lastRequestPlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcess?: string
    lastAvailablePlacardProcessStatus?: JouveProcessStatus
    publicationOnlineDate?: string
    isValid?: boolean
    authors?: ShortAuthor[]
    charVolume: number
    charDaVolume: number
    numberOfPages?: string
    nextPublicationOnlineUsername?: string
    nextPublicationOnlineDate?: string
}

export type ShortAuthor = {
    first_name?: string
    last_name?: string
    particle?: string
    id: string
}
