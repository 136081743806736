import {ArticleSearchFilters} from "../types/common-search";
import {baseParams} from "./common-utils";
import {buildQueryParams, ParameterType, readQueryParams} from "./query-param.utils";

const params = {
    ...baseParams,
    size: {
        type: ParameterType.NUMBER,
        default: 50
    },
    periodicalFilter: {
        type: ParameterType.TOKEN_ARRAY,
        setEmpty: true
    },
    publicationIdFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    publicationFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    natureFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    authorFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    planNodeFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    typeFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    statusFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    attachedFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    publicationDateFilter: {
        type: ParameterType.DATE_RANGE
    },
    imageFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    marbre: {
        type: ParameterType.BOOLEAN,
        default: false
    },
    numberOfPagesFilter: {
        type: ParameterType.STRING_ARRAY
    }
}

export const readArticleQueryParamsWithForceOptions = (search: string, options: ArticleSearchFilters): ArticleSearchFilters => {
    return {
        ...readQueryParams(params, search),
        ...options
    };
}

export const truncateString = (str, maxLength): string => {
    const trimmedString = str.substr(0, maxLength);
    if (str.length > trimmedString.length) {
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + ' [...]'
    }
    return str
}

export const removeHtmlTags = (html: string): string => {
    let tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}


export const buildPublicationArticleQueryParams = (filters: ArticleSearchFilters) => {
    return buildQueryParams(params, filters);
}

export const readArticleQueryParams = (search: string): ArticleSearchFilters => {
    return readQueryParams(params, search);
}

export const buildArticleQueryParams = (filters: ArticleSearchFilters) => {
    return buildQueryParams(params, filters);
}
