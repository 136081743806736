import React, {useState} from "react";
import {Grid, MuiThemeProvider, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {uuidv4} from "../../../utils/uuid";
import {PlanNode} from "../../../types/plan-node";
import {createMuiTheme} from "@material-ui/core/styles";
import BundleStructureTitleRenvoiEditField from "../../admin/bundle/edit/BundleStructureTitleRenvoiEditField";

type Props = {
    addNode: (node: PlanNode) => any
    inputLabel?: string
    buttonLabel?: string
    isBundleStructureEdition: boolean
}

export default function TreeAdder({addNode, inputLabel, buttonLabel, isBundleStructureEdition}: Props) {
    const [name, setName] = useState("")
    const [renvoiOk, setRenvoiOk] = useState(true)
    const [nameOk, setNameOk] = useState(!isBundleStructureEdition && name.length > 0)

    const submit = () => {
        addNode({
            id: uuidv4(),
            name: name,
            sub: [],
            type: "CONTAINER"
        });
        setName('');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && name.length > 0) {
            submit();
        }
    }

    const buttonTheme = createMuiTheme({
        palette: {
            primary: {
                light: 'rgba(175,175,175,0.8)',
                main: 'rgba(52,52,52,0.8)',
                dark: 'rgba(17,17,17,0.8)',
                contrastText: '#FFF',
            }
        },
    });

    return <Grid container spacing={2}>
                <Grid item xs={10}>
                    {!isBundleStructureEdition && <TextField
                        label={inputLabel ?? "Nom de la rubrique"}
                        required
                        fullWidth={true}
                        value={name}
                        autoFocus
                        onChange={v => {
                            setName(v.target.value)
                            setNameOk(v.target.value.length > 0)
                        }}
                        onKeyDown={handleKeyDown}
                    />}
                    {isBundleStructureEdition &&
                    <BundleStructureTitleRenvoiEditField
                        setRenvoiOk={setRenvoiOk}
                        fieldValue={name}
                        setFieldValue={setName}
                        setNameOk={setNameOk}
                    />}
                </Grid>

                <Grid item xs={2} alignItems="center" justifyContent="center">
                    <MuiThemeProvider theme={buttonTheme}>
                        <Button color={"primary"}
                                onClick={_ => submit()}
                                variant={"outlined"}
                                disabled={!nameOk || !renvoiOk}>
                            {buttonLabel ?? "Ajouter au CDF"}
                        </Button>
                    </MuiThemeProvider>
                </Grid>
    </Grid>
}
